import React, { Component } from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import logo from '../../assets/images/logo.svg'

const ScBrand = styled.div`
  display: inline-block;

  margin: 0;
  padding: 0;

  & > a {
    display: block;
    margin: 0;
    padding: 0;
  }

  & img {
    height: 50px;
    margin: 0;
    padding: 0;
  }
`

class Brand extends Component {
  render() {
    return (
      <ScBrand>
        <Link to="/">
          <img src={logo} alt="Muhsis, Web Tabanlı Ön Muhasebe Programı" />
        </Link>
      </ScBrand>
    )
  }
}

export default Brand
