import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { rgba, lighten } from 'polished'
import { Pane, Icon, Heading } from '@muhsis/ui'

import { theme } from '../../theme'

const ScCard = styled(Pane)`
  background-color: #fff;
  border: 1px solid ${theme.colors.border.muted};
  border-top: 5px solid ${theme.colors.border.default};
  border-radius: 0.25rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  margin-right: 0.5rem;
  text-align: left;
  transition: box-shadow 0.25s, border-color 0.25s;

  &:hover {
    box-shadow: 0 0 0 0.35rem ${rgba(theme.palette.primary.base, 0.2)};
    border-top-color: ${theme.palette.primary.base};
  }
`

const ScCardExclusive = styled(ScCard)`
  border-color: ${lighten(0.3, theme.palette.orange.base)};

  &:hover {
    box-shadow: 0 0 0 0.35rem ${rgba(theme.palette.orange.base, 0.2)};
    border-top-color: ${theme.palette.orange.base};
  }
`

class Card extends PureComponent {
  render() {
    return (
      <Pane>
        {!this.props.isExclusive && (
          <ScCard {...this.props}>
            {this.props.title && (
              <Heading
                size={500}
                color={theme.palette.primary.base}
                marginBottom="1rem"
              >
                {this.props.icon && (
                  <Icon
                    icon={this.props.icon}
                    color="currentColor"
                    size={20}
                    top="-2px"
                    position="relative"
                    marginRight="0.5rem"
                  />
                )}
                {this.props.title}
              </Heading>
            )}

            {this.props.children}
          </ScCard>
        )}

        {this.props.isExclusive && (
          <ScCardExclusive {...this.props}>
            {this.props.title && (
              <Heading
                size={500}
                color={theme.palette.primary.base}
                marginBottom="1rem"
              >
                {this.props.icon && (
                  <Icon
                    icon={this.props.icon}
                    color="currentColor"
                    size={20}
                    top="-2px"
                    position="relative"
                    marginRight="0.5rem"
                  />
                )}
                {this.props.title}
              </Heading>
            )}

            {this.props.children}
          </ScCardExclusive>
        )}
      </Pane>
    )
  }
}

export default Card
