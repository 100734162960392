export { default as imgLogo } from './logo.svg'
export { default as imgSymbolDark } from './symbol-dark.svg'
export { default as imgBodyBg } from './body-bg.png'
export { default as imgGridLight } from './grid-white.png'
export { default as imgMuhsisWeb } from './muhsis-web.png'

// Feature images
export { default as imgFeatureDocuments } from './feature-documents.png'
export { default as imgFeaturePayments } from './feature-payments.png'
export {
  default as imgFeatureReceiptDesigner,
} from './feature-receipt-designer.png'

export { default as imgPayAmex } from './pay-amex.svg'
export { default as imgPayMaster } from './pay-master.svg'
export { default as imgPayVisa } from './pay-visa.svg'

export { default as imgSecurityCloudflare } from './security-cloudflare.svg'
export { default as imgSecurityAzure } from './security-azure.svg'
export { default as imgSecurityAws } from './security-aws.svg'
