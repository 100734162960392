import React, { Component } from 'react'
import styled, { keyframes } from 'styled-components'
import { imgSymbolDark } from '../../assets/images'

const ScSplashScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const splashImageAnimation = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
`

const ScSplashImage = styled.img`
  height: 40px;
  animation: ${splashImageAnimation} 1.5s ease-in-out 0s infinite;
`

function LoadingMessage() {
  return (
    <ScSplashScreen>
      <ScSplashImage src={imgSymbolDark} alt="Muhsis açılıyor." />
    </ScSplashScreen>
  )
}

function withSplashScreen(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props)
      this.state = {
        loading: true,
      }
    }

    async componentDidMount() {
      setTimeout(() => {
        this.setState({
          loading: false,
        })
      }, 500)
    }

    render() {
      if (this.state.loading) return LoadingMessage()

      return <WrappedComponent {...this.props} />
    }
  }
}

export default withSplashScreen
