import React, { PureComponent } from 'react'
import styled from 'styled-components'
import symbol from '../../assets/images/symbol-dark.svg'

const ScBrand = styled.div`
  display: inline-block;

  margin: 0;
  padding: 0;

  & > img {
    height: 40px;
  }
`

class BrandSymbol extends PureComponent {
  render() {
    return (
      <ScBrand>
        <img src={symbol} alt="Muhsis" />
      </ScBrand>
    )
  }
}

export default BrandSymbol
