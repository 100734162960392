import React, { Component, Fragment } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider, Paragraph, CornerDialog } from '@muhsis/ui'
import { theme, GlobalStyle } from '../../theme'

class Layout extends Component {
  state = {
    isShown: false,
  }

  componentDidMount() {
    const gdprConsent = JSON.parse(localStorage.getItem('eu_gdpr_consent'))
    // If gdpr is not allowed
    if (!gdprConsent) {
      setTimeout(() => {
        this.setState({ isShown: true })
      }, 4000)
    }
  }

  giveGdprConsent() {
    localStorage.setItem('eu_gdpr_consent', true)
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <Fragment>
            <GlobalStyle />
            <ThemeProvider value={theme}>
              {this.props.children}
              <CornerDialog
                title="Kişisel Verilerin Korunması"
                isShown={this.state.isShown}
                width={400}
                hasCancel={false}
                confirmLabel="Tamam"
                onCloseComplete={this.giveGdprConsent}
              >
                <Paragraph>
                  Çerez politikamızda belirttiğimiz amaçlarla sınırlı ve
                  mevzuata uygun olarak sitemizde çerez kullanmaktayız. Detaylar
                  için
                  <Link to="/cerez-politikasi"> Çerez Politika'mızı </Link>
                  inceleyebilirsiniz.
                </Paragraph>
              </CornerDialog>
            </ThemeProvider>
          </Fragment>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
