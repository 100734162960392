import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { imgBodyBg } from '../../assets/images'

const ScHomeBackground = styled.section`
  background-image: url(${imgBodyBg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: bottom;
  background-position-x: center;
  padding-bottom: 3rem;
`

class BackgroundImageHome extends PureComponent {
  render() {
    return <ScHomeBackground>{this.props.children}</ScHomeBackground>
  }
}

export default BackgroundImageHome
