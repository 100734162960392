import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { imgGridLight } from '../../assets/images'

const ScGridBackground = styled.div`
  background-image: url(${imgGridLight});
  background-repeat: repeat-x;
  background-position: top;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
`

class BackgroundImageGrid extends PureComponent {
  render() {
    return <ScGridBackground>{this.props.children}</ScGridBackground>
  }
}

export default BackgroundImageGrid
