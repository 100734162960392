import { createGlobalStyle } from 'styled-components'
import { darken } from 'polished'
import reboot from 'styled-reboot'
import theme from './theme'

const options = {
  black: '#111',
  fontFamilyBase: theme.fontFamilies.ui,
  fontFamilyMonospace: theme.fontFamilies.mono,
  fontSizeBase: '1rem',
  fontWeightBase: 400,
  lineHeightBase: 1.5,
  bodyColor: theme.colors.text.default,
  linkColor: theme.colors.intent.none,
  linkDecoration: 'none',
  linkHoverColor: darken(0.2, theme.colors.intent.none),
  linkHoverDecoration: 'underline',
  tableCellPadding: '0.75rem',
  textMuted: theme.colors.text.muted,
}

const rebootCss = reboot(options)
const GlobalStyle = createGlobalStyle`
  ${rebootCss}
  /* other styles */

  textarea, input, button {
    -webkit-font-smoothing: auto !important;
  }

  textarea:focus,
  input:focus,
  button:focus {
    outline: none;
  }

  ::selection {
    background: #fff8e7;
    text-shadow: none;
  }
`

export default GlobalStyle
