import { createTheme } from '@muhsis/ui/commonjs/theme'

const theme = createTheme({
  controlStyle: 'gradients',
  palette: {
    primary: '#007ced',
    neutral: '#425A70',
    red: '#F44336',
    orange: '#FF9800',
    yellow: '#FFEB3B',
    green: '#31bd13',
    teal: '#009688',
    purple: '#9C27B0',
  },
  fontFamilies: {
    display: `"Montserrat", "SF UI Display", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    ui: `"Source Sans Pro", "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    mono: `"SF Mono", "Monaco", "Inconsolata", "Fira Mono", "Droid Sans Mono", "Source Code Pro", monospace`,
  },
})

export default theme
