import React, { Component } from 'react'
import styled from 'styled-components'

const ScImage = styled.img`
  max-width: 100%;
`

class ImageFluid extends Component {
  render() {
    return <ScImage {...this.props} />
  }
}

export default ImageFluid
